import { createRouter, createWebHashHistory } from "vue-router";
// import Router from 'vue-router'
const routes = [
  {
    path: "/",
    name: "Home",
    alias: "/home",
    component: () => import("../components/Home"),
    meta: {
      title: "الرئيسية",
      page: "home",
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../components/Profile"),
    meta: {
      title: "الملف الشخصي",
      page: "Profile",
    },
  },
  {
    path: "/contact-us",
    name: "contact us",
    component: () => import("../components/contact-us"),
    meta: {
      title: " اتصل بنا",
      page: "contact-us",
    },
  },
  {
    path: "/journals",
    name: "journals",
    component: () => import("../components/journals"),
    meta: {
      title: "المجلات العلمية",
      page: "journals",
    },
  },
  {
    path: "/journals/:id",
    name: "journal-details",
    component: () => import("../components/journal-details"),
    meta: {
      title: " عن  المجلة",
      page: "specialist-bio",
    },
  },

  {
    path: "/terms",
    name: "terms",
    component: () => import("../components/terms-conditions"),
    meta: {
      title: "الشروط والأحكام",
      page: "terms",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../components/Login"),
    meta: {
      title: "تسجيل الدخول",
      page: "Login",
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../components/SignUp"),
    meta: {
      title: " إنشاء حساب",
      page: "signup",
    },
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("../components/forget-pass"),
    meta: {
      title: " نسيت كلمة المرو",
      page: "signup",
    },
  },
  {
    path: "/verification-code",
    name: "verification-code",
    component: () => import("../components/verification-code"),
    meta: {
      title: "ادخال رمز التحقق",
      page: "verification-code",
    },
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../components/reset-pass"),
    meta: {
      title: "  اعادة تعيين كلمة المرور",
      page: "reset",
    },
  },
  {
    path: "/research-form",
    name: "research-form",
    component: () => import("../components/research-form"),
    meta: {
      title: " نشر البحث",
      page: "research-form",
    },
  },
  {
    path: "/order-form",
    name: "order-form",
    component: () => import("../components/order-form"),
    meta: {
      title: "طلب خدمة",
      page: "order-form",
    },
  },
  {
    path: "/my-researches",
    name: "my-research",
    component: () => import("../components/my-researches"),
    meta: {
      title: "my-researches",
      page: "my-researches",
    },
  },
  {
    path: "/services/:id",
    name: "service-details",
    component: () => import("../components/service-details"),
    meta: {
      title: " تفاصيل الخدمة",
      page: "service-details",
    },
  },
  {
    path: "/specialist-bio",
    name: "specialist-bio",
    component: () => import("../components/specialist"),
    meta: {
      title: " عرض بيانات المتخصص",
      page: "specialist-bio",
    },
  },
  {
    path: "/researchers-services",
    name: "researchers-services",
    component: () => import("../components/researchers-services"),
    meta: {
      title: " خدمات الباحثين",
      page: "researchers-services",
    },
  },
];
const router = createRouter({
  // linkActiveClass: 'active',
  // linkExactActiveClass: 'active',
  mode: "hash",
  history: createWebHashHistory(),
  routes,
});
export default router;

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
