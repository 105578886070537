<template>
  <header class="header-menu-area">
    <!-- end header-top -->
    <div class="header-menu-content pr-150px pl-150px">
      <div class="container">
        <div class="main-menu-content">
          <a href="#" class="down-button"><i class="la la-angle-down"></i></a>
          <div class="row align-items-center">
            <div class="col-lg-2">
              <div class="logo-box">
                <router-link to="/" class="logo"
                  ><img
                    src="../assets/images/logo1.png"
                    alt="logo"
                    height="90"
                    width="90"
                /></router-link>
                <div class="user-btn-action">
                  <div
                    class="off-canvas-menu-toggle main-menu-toggle icon-element icon-element-sm shadow-sm"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Main menu"
                  >
                    <i class="la la-bars"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- end col-lg-2 -->
            <div :class="isLoggedIn ? 'col-md-10d' : 'col-md-9d'">
              <div class="menu-wrapper">
                <nav class="main-menu font-weight-bold">
                  <ul>
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        {{ $t("message.aboutSotor") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/journals"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        {{ $t("message.magazines") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/researchers-services">
                        {{ $t("message.resServices") }}</router-link
                      >
                    </li>
                    <li>
                      <a href="https://linesofknowledge.osarh.pro/">
                        {{ $t("message.training") }}</a
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us">
                        {{ $t("message.contact") }}</router-link
                      >
                    </li>
                    <!-- <li v-if="isLoggedIn">
                      <router-link to="/my-researches">
                        {{ $t("message.myRes") }}</router-link
                      >
                    </li> -->
                    <li class="pointer" v-if="$root.$i18n.locale === 'ar'">
                      <span class="lang-li" @click="changeLocal('en')"
                        >English<i class="fas fa-globe"></i
                      ></span>
                    </li>
                    <li class="pointer" v-if="$root.$i18n.locale === 'en'">
                      <span class="lang-li ar-txt" @click="changeLocal('ar')">
                        العربية
                        <i class="fas fa-globe"></i>
                      </span>
                    </li>
                    <li v-if="isLoggedIn">
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-user"></i>
                          {{ userState.currentUser }}
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" href="#"
                            ><div class="drop-logout" v-if="isLoggedIn">
                              <section class="login-container-head">
                                <button
                                  class="login-button-head"
                                  @click="logout"
                                >
                                  <span class="visually-hidden-head"></span>
                                  {{ $t("message.logout") }}
                                </button>
                                <i class="fas fa-sign-out-alt"></i>
                              </section></div
                          ></a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <!-- end ul -->
                </nav>
                <!-- end nav-right-button -->
              </div>
              <!-- end menu-wrapper -->
              <div
                class="off-canvas-menu custom-scrollbar-styled main-off-canvas-menu"
              >
                <div
                  class="off-canvas-menu-close main-menu-close icon-element icon-element-sm shadow-sm"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Close menu"
                >
                  <i class="la la-times"></i>
                </div>
                <!-- end off-canvas-menu-close mob-menu start -->
                <div class="mt-100px mr-10px">
                  <ul class="text-center mob-menu">
                    <li>
                      <router-link
                        to="/"
                        aria-controls="aboutus"
                        aria-selected="false"
                      >
                        {{ $t("message.aboutSotor") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        to="/journals"
                        aria-controls="policy"
                        aria-selected="false"
                      >
                        {{ $t("message.magazines") }}</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/researchers-services">
                        {{ $t("message.resServices") }}</router-link
                      >
                    </li>
                    <li>
                      <a href="https://linesofknowledge.osarh.pro/">
                        {{ $t("message.training") }}</a
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us">
                        {{ $t("message.contact") }}</router-link
                      >
                    </li>
                    <!-- <li v-if="isLoggedIn">
                      <router-link to="/my-researches">
                        {{ $t("message.myRes") }}</router-link
                      >
                    </li> -->
                    <li class="pointer" v-if="$root.$i18n.locale === 'ar'">
                      <span class="lang-li" @click="changeLocal('en')"
                        >English<i class="fas fa-globe"></i
                      ></span>
                    </li>
                    <li
                      class="pointer"
                      v-if="
                        $root.$i18n.locale === 'en' || !$root?.$i18n?.locale
                      "
                    >
                      <span class="lang-li ar-txt" @click="changeLocal('ar')">
                        العربية
                        <i class="fas fa-globe"></i>
                      </span>
                    </li>
                    <li v-if="isLoggedIn">
                      <div class="dropdown">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fas fa-user"></i>
                          {{ userState.currentUser }}
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a class="dropdown-item" href="#"
                            ><div class="drop-logout" v-if="isLoggedIn">
                              <section class="login-container-head">
                                <button
                                  class="login-button-head"
                                  @click="logout"
                                >
                                  <span class="visually-hidden-head"></span>
                                  {{ $t("message.logout") }}
                                </button>
                                <i class="fas fa-sign-out-alt"></i>
                              </section></div
                          ></a>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="col-lg-2" v-if="!isLoggedIn">
                        <router-link to="/login">
                          <section class="login-container-head">
                            <button class="login-button-head">
                              <span class="visually-hidden-head">Login</span>
                              {{ $t("message.login") }}
                            </button>
                            <img
                              loading="lazy"
                              src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                              class="login-icon"
                              alt=""
                            />
                          </section>
                        </router-link>
                      </div>
                    </li>
                  </ul>
                  <!-- end ul -->
                </div>
              </div>
              <!-- end off-canvas-menu -->
              <div class="body-overlay"></div>
            </div>
            <div class="col-lg-2 d-none d-md-block" v-if="!isLoggedIn">
              <router-link to="/login">
                <section class="login-container-head">
                  <button class="login-button-head">
                    <span class="visually-hidden-head">Login</span>
                    {{ $t("message.login") }}
                  </button>
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/644abd708204860623b04ad1ff456a86cd52a09763455d3f12f82df947d1743f?placeholderIfAbsent=true&apiKey=dce22b09974a4778b0fbee70c15649cb"
                    class="login-icon"
                    alt=""
                  />
                </section>
              </router-link>
            </div>
            <!-- end col-lg-10 -->
          </div>
          <!-- end row align-items-center -->
        </div>
        <!-- end main-menu-content -->
      </div>
      <!-- end container-fluid -->
    </div>
    <!-- end header-menu-content -->
  </header>
</template>
<script setup>
import { inject, getCurrentInstance } from "vue";
import Swal from "sweetalert2";
const isLoggedIn = inject("isLoggedIn");
const userState = inject("userState");
const current = getCurrentInstance().proxy;
const changeLocal = (local) => {
  localStorage.Lang = local;
  current.$root.$i18n.locale = localStorage.Lang;
  const dir = local === "ar" ? "rtl" : "ltr";
  const bodyClass = local === "ar" ? "ar" : "en";
  localStorage.dir = dir;
  document.body.setAttribute("dir", dir);
  document.documentElement.setAttribute("dir", dir);

  document.body.classList.remove(local === "ar" ? "en" : "ar");
  document.body.classList.add(bodyClass);
};
const logout = async () => {
  const formData = new FormData();
  formData.append("token", localStorage.getItem("stoorToken"));

  try {
    const response = await current.$http.post(
      `${current.$hostUrl}logout`,
      formData
    );
    if (response.status === 200 && !response.data.error) {
      localStorage.removeItem("stoorToken");
      isLoggedIn.value = false;
      Swal.fire({
        icon: "success",
        title: response.data.message || "You have logged out successfully!",
        showConfirmButton: false,
        timer: 1200,
      });
      current.$router.push({ path: "/login" });
    } else {
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: response.data.message,
      });
    }
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Error",
      text:
        error.response?.data?.message ||
        "An unexpected error occurred. Please try again later.",
    });
    console.error("Error during login:", error);
  }
};
</script>
<style>
.btn-danger {
  color: #fff;
  background-color: #bd2130 !important;
  border-color: #b21f2d !important;
}
</style>
