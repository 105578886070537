/* eslint-disable vue/multi-word-component-names */
import { createApp, ref, reactive } from "vue";
import App from "./App.vue";
import jquery from "jquery";
import axios from "axios";
import i18n from "./i18n";
import "bootstrap";
import router from "./router/index";
import Vue3CountryIntl from "vue3-country-intl";
// import css
import "vue3-country-intl/lib/vue3-country-intl.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
const initializeLangSettings = () => {
  const lang = localStorage.Lang || "en";
  const dir = localStorage.dir || "ltr";
  document.body.setAttribute("dir", dir);
  document.documentElement.setAttribute("dir", dir);
  document.body.classList.add(lang);
  document.body.classList.remove(lang === "ar" ? "en" : "ar");
};

// Call this before creating the app
initializeLangSettings();
const app = createApp(App);
const isLoggedIn = ref(!!localStorage.getItem("stoorToken"));
app.provide("isLoggedIn", isLoggedIn);
const userState = reactive({
  currentUser: localStorage.stoorUser || "",
});
const setUser = (username) => {
  userState.currentUser = username;
  if (username) {
    localStorage.stoorUser = username;
  } else {
    localStorage.removeItem("stoorUser");
  }
};
// Provide the state and setter function
app.provide("userState", userState);
app.provide("setUser", setUser);
app.use(jquery);
app.use(i18n);
axios.interceptors.request.use((req) => {
  req.headers["Accept-Language"] = localStorage.Lang || "en";
  req.headers.authorization = "Bearer " + localStorage.stoorToken;
  jquery(".spinner-container").show();
  return req;
});
axios.interceptors.response.use(function (response) {
  jquery(".spinner-container").hide();
  return response;
});
app.component(Vue3CountryIntl.name, Vue3CountryIntl);
app.component("v-select", vSelect);
app.config.globalProperties.$http = axios;
app.config.globalProperties.$hostUrl = process.env.VUE_APP_BACKEND_SERVER;
app.use(router).mount("#app");
