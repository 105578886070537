<!-- eslint-disable no-unused-vars -->
<template>
  <!-- <div class="spinner-container">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div> -->
  <Navbar />
  <router-view />
  <Footer />
</template>

<script>
import $ from "jquery";
import Navbar from "@/layout/nav-bar.vue";
import Footer from "@/layout/Footer.vue";
export default {
  name: "App",
  components: {
    Navbar,
    Footer,
  },
  data() {},
  watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler(to, _from) {
        document.title = "سطور المعرفة" + " | " + to.meta.title || "";
      },
      immediate: true,
    },
  },

  mounted() {
    //back to top button control
    const $dom = $("html, body");
    $(document).on("click", "#scroll-top", function () {
      $($dom).animate({ scrollTop: 0 }, 1000);
    });
    // mobile menu
    /*=========== Main menu open ============*/
    var mainMenuToggle = $(".main-menu-toggle");

    mainMenuToggle.on("click", function () {
      $(".main-off-canvas-menu, .body-overlay").addClass("active");
      $("body").css({ overflow: "hidden" });
    });

    /*=========== Main menu close ============*/
    var mainMenuClose = $(".main-menu-close, .body-overlay");
    mainMenuClose.on("click", function () {
      $(".main-off-canvas-menu, .body-overlay").removeClass("active");
      $("body").css({ overflow: "inherit" });
    });
    const menuItems = document.querySelectorAll("ul.mob-menu li");
    menuItems.forEach((item) => {
      item.addEventListener("click", () => {
        console.log("clicked");
        document
          .querySelector(".main-off-canvas-menu")
          .classList.remove("active");
        document.querySelector(".body-overlay").classList.remove("active");
        document.body.style.overflow = "inherit";
      });
    });

    /*=========== User menu open ============*/
    var userMenuToggle = $(".user-menu-toggle");

    userMenuToggle.on("click", function () {
      $(".user-off-canvas-menu, .body-overlay").addClass("active");
      $("body").css({ overflow: "hidden" });
    });

    /*=========== User menu close ============*/
    var userMenuClose = $(".user-menu-close, .body-overlay");

    userMenuClose.on("click", function () {
      $(".user-off-canvas-menu, .body-overlay").removeClass("active");
      $("body").css({ overflow: "inherit" });
    });
  },
};
</script>
<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}
@import "assets/css/bootstrap.min.css";
@import "assets/css/bootstrap-rtl.min.css";
@import "assets/css/line-awesome.css";
@import "assets/css/owl.carousel.min.css";
@import "assets/css/owl.theme.default.min.css";
@import "assets/css/bootstrap-select.min.css";
@import "assets/css/fancybox.css";
@import "assets/css/tooltipster.bundle.css";
@import "assets/css/style.css";
@import "assets/css/style-rtl.css";
@font-face {
  font-family: "Al-Jazeera-Arabic";
  src: url("@/assets/fonts/Al Jazeera Arabic Bold - [UrduFonts.com].ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Al-Jazeera-Arabic";
  src: url("@/assets/fonts/Al Jazeera Arabic Light - [UrduFonts.com].ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Al-Jazeera-Arabic";
  src: url("@/assets/fonts/Al Jazeera Arabic Regular - [UrduFonts.com].ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}
</style>
<style lang="scss">
@import "assets/scss/main.scss";
@import "assets/scss/ar.scss";
@import "assets/scss/en.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
// body {
//   margin: 0px !important;
// }

// @import "assets/vendor/bootstrap/css/bootstrap-ar.min";
// @import "assets/scss/partials/_variables.css";
// @import "assets/scss/partials/_custom.scss";
</style>
